import React, { useState, useEffect } from 'react';
import { 
  FaFolder, FaFile, FaFileImage, FaFilePdf, FaFileArchive, FaFileCode, 
  FaArrowLeft, FaListUl, FaThLarge, FaThList, FaFileAlt, FaFileVideo,
  FaFileCsv, FaFileExcel, FaFilePowerpoint, FaFileWord, FaDownload, FaEye
} from 'react-icons/fa';
import './Printing3DTab.css';

const Printing3DTab = () => {
  const [currentPath, setCurrentPath] = useState('');
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [viewMode, setViewMode] = useState('details'); // 'details', 'list', or 'grid'
  const [selectedItem, setSelectedItem] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' 
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';

  // Function to fetch items from the current path
  const fetchItems = async (path = '') => {
    setLoading(true);
    setError('');
    
    try {
      const encodedPath = encodeURIComponent(path);
      console.log(`Fetching items from path: ${encodedPath}`);
      const response = await fetch(`${API_URL}/3dprinting/browse/${encodedPath}`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        const errorMessage = errorData.error || `Server responded with status: ${response.status}`;
        console.error('Error response:', errorData);
        throw new Error(errorMessage);
      }
      
      const data = await response.json();
      console.log('Received data:', data);
      setItems(data.items || []);
      setCurrentPath(path);
      
      // Update breadcrumbs
      if (path === '') {
        setBreadcrumbs([{ name: 'Home', path: '' }]);
      } else {
        const parts = path.split('/');
        const crumbs = parts.map((part, index) => {
          const crumbPath = parts.slice(0, index + 1).join('/');
          return { name: part, path: crumbPath };
        });
        setBreadcrumbs([{ name: 'Home', path: '' }, ...crumbs]);
      }
    } catch (err) {
      console.error('Error fetching 3D printing files:', err);
      setError(`Error fetching files: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Fetch items on component mount and when path changes
  useEffect(() => {
    fetchItems(currentPath);
  }, []);

  // Function to handle clicking on a folder
  const handleFolderClick = (folderPath) => {
    fetchItems(folderPath);
  };

  // Function to go back to parent directory
  const handleGoBack = () => {
    if (currentPath === '') return;
    
    const parts = currentPath.split('/');
    parts.pop();
    const parentPath = parts.join('/');
    
    fetchItems(parentPath);
  };

  // Function to navigate using breadcrumbs
  const handleBreadcrumbClick = (path) => {
    fetchItems(path);
  };

  // Function to format file size
  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  // Function to determine icon based on file extension
  const getFileIcon = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    
    switch (extension) {
      // 3D printing file formats
      case 'stl':
      case 'obj':
      case '3mf':
      case 'gcode':
        return <FaFileCode className="file-icon file-icon-3d" />;
      
      // Image files
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'bmp':
      case 'webp':
      case 'svg':
        return <FaFileImage className="file-icon file-icon-image" />;
      
      // Document files
      case 'pdf':
        return <FaFilePdf className="file-icon file-icon-pdf" />;
      case 'doc':
      case 'docx':
        return <FaFileWord className="file-icon file-icon-doc" />;
      case 'xls':
      case 'xlsx':
        return <FaFileExcel className="file-icon file-icon-excel" />;
      case 'ppt':
      case 'pptx':
        return <FaFilePowerpoint className="file-icon file-icon-ppt" />;
      case 'txt':
      case 'md':
        return <FaFileAlt className="file-icon file-icon-text" />;
      case 'csv':
        return <FaFileCsv className="file-icon file-icon-csv" />;
      
      // Archive files
      case 'zip':
      case 'rar':
      case '7z':
      case 'gz':
      case 'tar':
        return <FaFileArchive className="file-icon file-icon-archive" />;
      
      // Video files
      case 'mp4':
      case 'avi':
      case 'mov':
      case 'wmv':
      case 'mkv':
        return <FaFileVideo className="file-icon file-icon-video" />;
      
      // Default for other file types
      default:
        return <FaFile className="file-icon" />;
    }
  };

  // Function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  // Function to check if file is an image
  const isImageFile = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    return ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'].includes(extension);
  };

  // Function to check if file is a 3D file
  const is3DFile = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    return ['stl', 'obj', '3mf', 'gcode'].includes(extension);
  };

  // Function to handle image preview
  const handlePreview = (item) => {
    setSelectedItem(item);
    setShowPreview(true);
  };

  // Function to close preview
  const handleClosePreview = () => {
    setShowPreview(false);
    setSelectedItem(null);
  };

  // Get file URL - ensure URLs work correctly in both development and production
  const getFileUrl = (item) => {
    // Check if the URL is already absolute (starts with http:// or https://)
    if (!item.url) {
      console.error("Missing URL for item:", item);
      return "#";
    }
    
    if (item.url.startsWith('http://') || item.url.startsWith('https://')) {
      return item.url;
    }
    
    // Check if item.url is a full path starting with a slash
    const baseUrl = window.location.hostname === 'localhost' 
      ? 'http://localhost:3001'
      : window.location.origin;
      
    // Ensure URL starts with a slash
    const urlPath = item.url.startsWith('/') ? item.url : `/${item.url}`;
    
    // Make sure the URL is properly encoded
    if (urlPath.includes('%20') || !urlPath.includes(' ')) {
      // If it's already encoded or has no spaces, use as is
      return `${baseUrl}${urlPath}`;
    } else {
      // Otherwise encode it
      return `${baseUrl}${encodeURI(urlPath)}`;
    }
  };

  // Render details view (default)
  const renderDetailsView = () => (
    <div className="files-list files-details-view">
      <div className="files-header">
        <div className="file-name-header">Name</div>
        <div className="file-size-header">Size</div>
        <div className="file-date-header">Modified</div>
        <div className="file-actions-header">Actions</div>
      </div>
      
      {items.map((item, index) => (
        <div key={index} className="file-item">
          {item.isDirectory ? (
            <div 
              className="file-name folder"
              onClick={() => handleFolderClick(item.path)}
            >
              <FaFolder className="file-icon folder-icon" />
              <span>{item.name}</span>
            </div>
          ) : (
            <div className="file-name">
              {getFileIcon(item.name)}
              <span className="file-name-text">{item.name}</span>
            </div>
          )}
          <div className="file-size">{formatFileSize(item.size)}</div>
          <div className="file-date">{formatDate(item.modified)}</div>
          <div className="file-actions">
            {!item.isDirectory && (
              <>
                <a 
                  href={getFileUrl(item)} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  download
                  className="file-action-button download-button"
                  title="Download file"
                >
                  <FaDownload />
                </a>
                {isImageFile(item.name) && (
                  <button 
                    onClick={() => handlePreview(item)} 
                    className="file-action-button preview-button"
                    title="Preview image"
                  >
                    <FaEye />
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  // Render list view (more compact)
  const renderListView = () => (
    <div className="files-list files-list-view">
      {items.map((item, index) => (
        <div key={index} className="file-item-list">
          <div 
            className={`file-name ${item.isDirectory ? 'folder' : ''}`}
            onClick={item.isDirectory ? () => handleFolderClick(item.path) : undefined}
          >
            {item.isDirectory ? 
              <FaFolder className="file-icon folder-icon" /> : 
              getFileIcon(item.name)
            }
            <span className="file-name-text">{item.name}</span>
            <span className="file-meta">
              {formatFileSize(item.size)} - {formatDate(item.modified)}
            </span>
          </div>
          {!item.isDirectory && (
            <div className="file-actions">
              <a 
                href={getFileUrl(item)} 
                target="_blank" 
                rel="noopener noreferrer"
                download
                className="file-action-button download-button"
                title="Download file"
              >
                <FaDownload />
              </a>
              {isImageFile(item.name) && (
                <button 
                  onClick={() => handlePreview(item)} 
                  className="file-action-button preview-button"
                  title="Preview image"
                >
                  <FaEye />
                </button>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );

  // Render grid/thumbnail view
  const renderGridView = () => (
    <div className="files-grid-view">
      {items.map((item, index) => (
        <div 
          key={index} 
          className={`grid-item ${item.isDirectory ? 'grid-folder' : 'grid-file'}`}
          onClick={item.isDirectory ? () => handleFolderClick(item.path) : undefined}
        >
          <div className="grid-item-icon">
            {item.isDirectory ? (
              <FaFolder className="grid-folder-icon" />
            ) : isImageFile(item.name) ? (
              <div className="grid-thumbnail">
                <img 
                  src={getFileUrl(item)} 
                  alt={item.name} 
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 24 24'%3E%3Cpath fill='%23ccc' d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 16H6c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v12c0 .55-.45 1-1 1zM12 8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z'/%3E%3C/svg%3E";
                  }}
                />
              </div>
            ) : (
              getFileIcon(item.name)
            )}
          </div>
          <div className="grid-item-name" title={item.name}>
            {item.name}
          </div>
          {!item.isDirectory && (
            <div className="grid-item-actions">
              <a 
                href={getFileUrl(item)} 
                target="_blank" 
                rel="noopener noreferrer"
                download
                className="file-action-button download-button"
                title="Download file"
                onClick={(e) => e.stopPropagation()}
              >
                <FaDownload />
              </a>
              {isImageFile(item.name) && (
                <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePreview(item);
                  }} 
                  className="file-action-button preview-button"
                  title="Preview image"
                >
                  <FaEye />
                </button>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );

  // Image preview modal
  const renderPreviewModal = () => {
    if (!showPreview || !selectedItem) return null;
    
    return (
      <div className="preview-modal-overlay" onClick={handleClosePreview}>
        <div className="preview-modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="preview-modal-header">
            <h3>{selectedItem.name}</h3>
            <button className="preview-close-button" onClick={handleClosePreview}>×</button>
          </div>
          <div className="preview-modal-body">
            <img 
              src={getFileUrl(selectedItem)} 
              alt={selectedItem.name} 
              className="preview-image"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 24 24'%3E%3Cpath fill='%23ccc' d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 16H6c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v12c0 .55-.45 1-1 1zM12 8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z'/%3E%3C/svg%3E";
              }}
            />
          </div>
          <div className="preview-modal-footer">
            <a 
              href={getFileUrl(selectedItem)} 
              download
              className="preview-download-button"
              target="_blank" 
              rel="noopener noreferrer"
            >
              <FaDownload /> Download
            </a>
            <div className="preview-file-info">
              <div>{formatFileSize(selectedItem.size)}</div>
              <div>{formatDate(selectedItem.modified)}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="printing-3d-tab">
      <h3>3D Printing Files</h3>
      
      <div className="file-browser">
        <div className="browser-toolbar">
          {/* Breadcrumbs navigation */}
          <div className="breadcrumbs">
            {breadcrumbs.map((crumb, index) => (
              <React.Fragment key={crumb.path}>
                {index > 0 && <span className="breadcrumb-separator">/</span>}
                <button 
                  className="breadcrumb-button"
                  onClick={() => handleBreadcrumbClick(crumb.path)}
                >
                  {crumb.name}
                </button>
              </React.Fragment>
            ))}
          </div>
          
          {/* View mode switcher */}
          <div className="view-mode-switcher">
            <button 
              className={`view-mode-button ${viewMode === 'details' ? 'active' : ''}`}
              onClick={() => setViewMode('details')}
              title="Details view"
            >
              <FaThList />
            </button>
            <button 
              className={`view-mode-button ${viewMode === 'list' ? 'active' : ''}`}
              onClick={() => setViewMode('list')}
              title="List view"
            >
              <FaListUl />
            </button>
            <button 
              className={`view-mode-button ${viewMode === 'grid' ? 'active' : ''}`}
              onClick={() => setViewMode('grid')}
              title="Grid view"
            >
              <FaThLarge />
            </button>
          </div>
        </div>
        
        {/* Back button (only show if not at root) */}
        {currentPath !== '' && (
          <button className="back-button" onClick={handleGoBack}>
            <FaArrowLeft /> Back
          </button>
        )}
        
        {/* Loading indicator */}
        {loading && (
          <div className="loading-files">
            <div className="loading-spinner"></div>
            <p>Loading files...</p>
          </div>
        )}
        
        {/* Error message */}
        {error && (
          <div className="files-error">
            <p>{error}</p>
            <button className="retry-button" onClick={() => fetchItems(currentPath)}>Retry</button>
          </div>
        )}
        
        {/* File/folder list */}
        {!loading && !error && (
          <>
            {items.length === 0 ? (
              <div className="no-files">
                <p>No files or folders found.</p>
                <p className="permissions-notice">
                  If you're seeing this unexpectedly, check that the folder exists and has proper read permissions:<br />
                  <code>D:\3D Printing</code>
                </p>
              </div>
            ) : (
              <>
                {viewMode === 'details' && renderDetailsView()}
                {viewMode === 'list' && renderListView()}
                {viewMode === 'grid' && renderGridView()}
              </>
            )}
          </>
        )}
      </div>
      
      {/* Preview modal for images */}
      {renderPreviewModal()}
    </div>
  );
};

export default Printing3DTab; 